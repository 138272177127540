/**
 * @deprecated Use `isValidCpf` from `shared/utilities/utility-validators` instead.
 */
export function isValidCpf(cpf: string, withMaskValidation = false) {
  const unmaskedCpf = cpf.replace(/\D/g, '')

  if (withMaskValidation && !/^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(cpf)) {
    return false
  }

  if (unmaskedCpf.length !== 11) {
    return false
  }

  if (/^(\d)\1{10}/g.test(unmaskedCpf)) {
    return false
  }

  const lastTwoDigits = unmaskedCpf.slice(-2)

  function calculateDigit(digits: number[]) {
    const sum = digits.reduce(
      (previousValue, currentValue, index) => previousValue + currentValue * (digits.length + 1 - index),
      0
    )
    const quotient = (sum * 10) % 11
    return quotient === 10 ? 0 : quotient
  }

  const firstNineDigits = unmaskedCpf.split('').slice(0, 9).map(Number)
  const tenDigit = calculateDigit(firstNineDigits)

  if (Number(lastTwoDigits[0]) !== tenDigit) {
    return false
  }

  const firstTenDigits = [...firstNineDigits, tenDigit]
  const elevenDigit = calculateDigit(firstTenDigits)

  if (Number(lastTwoDigits[1]) !== elevenDigit) {
    return false
  }

  return true
}

/**
 * @deprecated Use `isValidCpf` from `shared/utilities/utility-validators` instead.
 */
export function isValidCnpj(cnpj: string, withMaskValidation = false) {
  const unmakedCnpj = cnpj.replace(/\D/g, '')

  if (withMaskValidation && !/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(cnpj)) {
    return false
  }

  if (unmakedCnpj.length !== 14) {
    return false
  }

  if (/^(\d)\1{13}/g.test(unmakedCnpj)) {
    return false
  }

  const lastTwoDigits = unmakedCnpj.slice(-2)

  function calculateDigit(digits: number[], table: number[]) {
    const sum = digits.reduce((previousValue, currentValue, index) => previousValue + currentValue * table[index], 0)
    const quotient = sum % 11
    return quotient < 2 ? 0 : 11 - quotient
  }

  const tableToCalculateFirstDigit = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
  const firstTwelveDigits = unmakedCnpj.split('').slice(0, 12).map(Number)
  const thirteenDigit = calculateDigit(firstTwelveDigits, tableToCalculateFirstDigit)

  if (Number(lastTwoDigits[0]) !== thirteenDigit) {
    return false
  }

  const tableToCalculateSecondDigit = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
  const firstThirteenDigits = [...firstTwelveDigits, thirteenDigit]
  const fourteenDigit = calculateDigit(firstThirteenDigits, tableToCalculateSecondDigit)

  if (Number(lastTwoDigits[1]) !== fourteenDigit) {
    return false
  }

  return true
}

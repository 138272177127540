import { EMAIL_REGEX, PHONE_REGEX } from '~/lib/constants'

/**
 * @deprecated Just checks if a string is null, undefined, empty, or contains only whitespace.
 *
 * @example
 * ```ts
 * if (value == null || value.trim() === '') {
 *  console.log('Value is null, undefined, empty, or contains only whitespace.')
 * }
 * ```
 */
export const isNullOrWhiteSpace = (value?: string | null): boolean => {
  return !value || !value.trim()
}

/**
 * @deprecated Use zod's `email()` instead.
 *
 * @example
 * ```ts
 * import { z } from 'zod'
 *
 * const parsed = z.string().email().parse('johndoe@mail.com') // or `.safeParse()`
 * console.log(parsed) // Output: 'johndoe@mail.com' or throws an error if the email is invalid.
 * ```
 */
export const emailValidator = (email?: string): boolean => {
  if (!isNullOrWhiteSpace(email)) {
    if (email?.match(EMAIL_REGEX)) {
      return true
    }
  }
  return false
}

/**
 * @deprecated Use `isValidPhoneNumber` from `shared/utilities/utility-validators` instead.
 */
export const telValidator = (telefone?: string): boolean => {
  if (telefone) {
    if (telefone.match(PHONE_REGEX)) {
      return true
    }
  }
  return false
}
